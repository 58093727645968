export const environment = {
  production: false,
  apiUrl: 'https://dev.plt.api.innovaweb.cl/graphql',
  restUrl: 'https://dev.plt.api.innovaweb.cl',
  chileApiUrl: '/chile',
  fintoc: {
    publicKey: 'pk_live_1qAJkzgsQ4PfawxpQPWwNMkhQGuwmKZD',
    webhookUrl: 'https://dev.plt.api.innovaweb.cl/bank/webhook',
  },
  pusher: {
    key: '7f12e40f775c4e46d975',
    cluster: 'mt1'
  },
};
